.server-config-section {
   border: 1px solid var(--bs-gray-200);

   .server-config-section-title {
      padding: 17px 20px;
      font-weight: 500;
      border-bottom: 1px solid #ddd;
      background-color: var(--bs-gray-200);

   }

   .ant-radio-group,
   .ant-space-vertical {
      float: left;
      width: 100%;
      gap: 0 !important;
   }

   .ant-space-item {
      border-bottom: 1px solid var(--bs-gray-300);

      &:last-child {
         border-bottom-color: transparent;
      }
   }

   .ant-radio  {
      float: left;
      width: 16px;
      display: block;
   }

   .ant-radio+span {
      float: left;
      width: calc(100% - 16px);
   }

   .selectRadioBtn {
      padding: 20px 20px 30px 20px;
      display: flex;
      align-items: center;
      position: relative;
   }

   .config-radio-label {
      padding-left: 20px;
   }

   .ant-radio-wrapper-checked {
      background-color: #FFF9C4;
   }

   .select-server {
      display: flex;
      flex-direction: row;
      align-items: center;
   }

   .config-server-details,
   .config-location-title {
      float: left;
      width: calc(100% - 200px);
      padding-right: 20px;
   }

   .config-price-block,
   .config-location-price {
      float: left;
      width: 200px;
      padding-left: 20px;
   }

   .config-price-text {
      font-weight: 600;
      text-align: right;
      font-size: 16px;

      span {
         font-weight: normal;
      }
   }

   .config-location-price {
      &.free {
         font-weight: normal;
         font-size: 16px;
      }
   }

   .price-convertion {
      float: right;
      position: absolute;
      right: 2px;
      bottom: 2px;
      font-size: 12px;
      background-color: var(--bs-gray-600);
      color: #fff;
      padding: 2px 5px;
      border-radius: 3px;
      line-height: 1;
   }

   .config-server-title  {
      font-weight: 500;
      font-size: 16px;
   }

   .config-server-info  {
      font-size: 14px;
      color: #666;
      padding-top: 6px;
   }

   .config-location-title {
      font-size: 16px;
   }
}

.server-configur-helper-info {
   font-size: 14px;
   line-height: 1.5;
   color: #666;
   padding: 0px 25px 20px 25px;
}

.final-order-pricing-block {
   position: fixed;
   bottom: 0;
   left: 16.66666667%;
   right: 0;
   bottom: 0;
   background-color: #fff;
   box-shadow: 2px -2px 3px rgba(0, 0, 0, 0.15);
   padding: 20px;
   display: flex;
   flex-direction: row;


   .final-order-info {
      float: left;
      width: calc(100% - 500px);
   }

   .final-order-actions-block {
      float: left;
      width: 500px;
      text-align: right;

      button {
         margin-left: 10px;
      }
   }

   .order-info-title {
      font-size: 18px;
   }

   .order-misc-info {
      font-size: 13px;
      color: var(--bs-info);
   }

}