.profile-card {
   .ant-card-head-title,
   .ant-card-extra {
      padding-top: 10px;
      padding-bottom: 10px;
   }

   .ant-card-head {
      background-color: var(--bs-gray-100);
   }
}

.account-list-row {
   li {
      float: left;
      width: 100%;
      margin-bottom: 15px;

      &:last-child {
         margin-bottom: 0;
      }

   }
}

.card-edit-btn {
   display: inline-flex;
   align-items: center;
   justify-content: center;

   svg {
      margin-right: 3px;
      height: 14px;
      width: 14px;
   }
}