.ibee-theme {
  //Slider
  .ant-slider {
    .ant-slider-track {
      background-color: var(--bs-primary);
    }

    .ant-slider-handle {
      border-color: var(--bs-primary);
      //background-color: var(--bs-primary);

      &:focus {
        box-shadow: 0 0 0 5px rgb(242 101 34 / 12%);
      }
    }

    .ant-slider-dot {
      //border-color: var(--bs-gray-400);
      border-color: var(--bs-primary);
      //background-color: var(--bs-primary);
      width: 10px;
      height: 10px;
      top: -3px;

      &.ant-slider-dot-active {
        background-color: var(--bs-primary);
      }
    }

    &:hover {
      .ant-slider-track {
        background-color: var(--bs-primary);
        filter: contrast(1);
      }

      .ant-slider-handle {
        border-color: var(--bs-primary);

        &:not(.ant-tooltip-open) {
          border-color: var(--bs-primary);
        }
      }
    }
  }

  .ant-slider-mark-text {
    margin-top: 3px;
  }

  //Radio Buttons

  .ant-radio {
    .ant-radio-inner {
      border-color: var(--bs-primary);

      &::after {
        background-color: var(--bs-primary);
      }
    }

    &:hover {
       .ant-radio-inner {
          border-color: var(--bs-primary);
       }
    }
  }
}
