.footer-icon-btn {
   padding: 0;
   width: 30px;
   height: 30px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.createNewTabs {
   .tabs-left-title {
      font-size: 18px;
      font-weight: 500;
   }
   .ant-tabs-nav {
      background-color: var(--bs-gray-300);
      padding: 0px 20px;
   }
   .ant-tabs-nav-wrap {
      padding-left: 20px;
      justify-content: flex-end;
   }
}

.create-new-form {
   @media screen and (min-width: 1400px) {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 30px;
      row-gap: 15px;

      .ant-form-item {
         margin-bottom: 0;
      }
   }
}