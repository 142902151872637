.each-pricing-col {
   /* border: 1px solid #ccc; */
   box-shadow: 0 0 10px rgba(0,0,0,0.15);
   padding: 25px 30px;
   display: flex;
   align-items: center;

   .each-pricing-title {
      font-size: 26px;
      font-weight: 400;
      float: left;
      width: calc(100% - 90px);
   }

   .each-pricing-price-block {
      float: right;
      width: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      background-color: #eee;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      padding: 10px;
      text-align: center;
      color: #111;

      svg {
         font-size: 14px;
         line-height: 1;
      }

      span {
         font-size: 15px;
         display: block;
         line-height: 0.8;
         color: #666;
      }
   }


}

.pricingTable {
   .ant-table-cell {
      align-items: center;
   }
}

.filesActionBtn {
   display: inline-flex;
   align-items: center;

   svg {
      margin-right: 5px;
   }
}