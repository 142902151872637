.order-list {
   li {
      float: left;
      width: 100%;
      border-bottom: 1px solid #ddd;

      &:first-child {
         padding-top: 0;
      }

      &:last-child {
         padding-bottom: 0;
         border-bottom: none;
      }
   }
}

.each-server-order {
   border: none;
   cursor: pointer;
   display: flex;
   flex-direction: row;
   align-items: stretch;
   height: auto !important;
   padding: 20px;
   white-space: normal !important;
   text-align: left !important;

   .each-server-info {
      float: left;
      width: calc(100% - 400px);
      color: #333;
      padding-right: 20px;
   }

   .each-server-pricing-block {
      float: left;
      width: 250px;
      padding: 30px 20px 0 40px;
      color: #333;
      display: flex;
      flex-direction: column;
      align-items: center;
   }

   .order-block {
      float: left;
      width: 150px;
      color: #333;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: 50px;
      padding-right: 10px;
   }

   .each-server-title  {
      font-size: 18px;
      line-height: 1.2;
      font-weight: 500;
   }

   .each-server-desc {
      padding: 7px 0;
      font-size: 14px;
   }

   .each-server-provisioning {
      font-size: 13px;
      color: var(--bs-blue);
   }

   .server-price {
      font-size: 16px;
   }

   .server-price-number {
      //color: var(--bs-primary);
      font-weight: 600;
   }

   .currency-converter-block {
      padding-top: 3px;
      text-align: right;
   }

   .converter-text {
      font-size: 12px;
      line-height: 1.1;
      background-color: var(--bs-gray-600);
      color: white;
      padding: 3px 5px;
      border-radius: 3px;
   }

   .order-now-btn {
      background-color: var(--bs-primary);
      padding: 8px 20px;
      display: inline-block;
      color: white;
      border-radius: 3px;

      &:hover {
         filter: contrast(1.7);
      }
   }

   &:hover {
      background-color: var(--bs-gray-200) !important;
   }


}
